import { Accessory, ExternalWebserviceLog } from 'src/app/models/shipping-models';
import { Shipping, ShippingDetail, ShippingDetailLight, ShippingLight, ShippingComplete, ShippingDetailExternal } from './../../models/shipping-models';
import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import { Meta } from "src/app/models/common-models";
import { pageTableState } from "src/app/models/store-utility-models";
import { ACTION_SHIPMENT } from 'src/app/shared/enums/shipping.enums';
import { productTypeDescriptions, allProductsDescriptions } from 'src/app/config/common';

@Injectable({ providedIn: 'root' })
export class ShippingStore {

  private shippings: ShippingLight[] = [];
  private _shippings = new BehaviorSubject<ShippingLight[]>([]);
  shippings$ = this._shippings.asObservable();

  private shippingDetails: ShippingDetailLight[] = [];
  private _shippingDetails = new BehaviorSubject<ShippingDetailLight[]>([]);
  shippingDetails$ = this._shippingDetails.asObservable();


  private shippingDetailsExternal: ShippingDetailExternal[] = [];
  private _shippingDetailsExternal = new BehaviorSubject<ShippingDetailExternal[]>([]);
  shippingDetailsExternal$ = this._shippingDetailsExternal.asObservable();


  private shippingDraft: ShippingComplete | null = null;
  private _shippingDraft = new BehaviorSubject<ShippingComplete | null>(null);
  shippingDraft$ = this._shippingDraft.asObservable();

  private shippingsToProcess: ShippingDetailLight[] = [];
  private _shippingsToProcess = new BehaviorSubject<ShippingDetailLight[]>([]);
  shippingsToProcess$ = this._shippingsToProcess.asObservable();

  private _selectedShipping = new BehaviorSubject<ShippingLight | null>(null);
  selectedShipping$ = this._selectedShipping.asObservable();

  private _selectedShippingDetail = new BehaviorSubject<ShippingDetail | null>(
    null
  );
  selectedShippingDetail$ = this._selectedShippingDetail.asObservable();

  private metaShipping: Meta | null = null;
  private _metaShipping = new BehaviorSubject<Meta | null>(null);
  metaShipping$ = this._metaShipping.asObservable();

  private _metaShippingDetail = new BehaviorSubject<Meta | null>(null);
  metaShippingDetail$ = this._metaShippingDetail.asObservable();

  // private _meta = new BehaviorSubject<Meta | null>(null);
  // meta$ = this._meta.asObservable();

  private _metaShippingsToProcess = new BehaviorSubject<Meta | null>(null);
  metaShippingsToProcess$ = this._metaShippingsToProcess.asObservable();

  private _changePageTableShippingState = new BehaviorSubject<pageTableState>({
    perPage: 15,
    page: 1,
  });
  changePageTableShippingState$ =
    this._changePageTableShippingState.asObservable();

  private _changePageTableShippingDetailState =
    new BehaviorSubject<pageTableState>({
      perPage: 15,
      page: 1,
    });
  changePageTableShippingDetailState$ =
    this._changePageTableShippingDetailState.asObservable();

  private _changePageShippingToProcessTableState =
    new BehaviorSubject<pageTableState>({
      perPage: 15,
      page: 1,
    });
  changePageShippingToProcessTableState$ =
    this._changePageShippingToProcessTableState.asObservable();

  private barcodeShippingId: number | null = null;
  private _barcodeShippingId = new BehaviorSubject<number | null>(null);
  barcodeShippingId$ = this._barcodeShippingId.asObservable();

  barcodeShipping$ = combineLatest([this.barcodeShippingId$, this.shippingDetailsExternal$]).pipe(
    map(([selShipping, shippingDetails]) => shippingDetails.find((sd) => sd.id == selShipping) || null)
  );

  private selectedLogId: number | null = null;
  private _selectedLogId = new BehaviorSubject<number | null>(null);
  selectedLogId$ = this._selectedLogId.asObservable();
  selectedLog$ = combineLatest([this.selectedLogId$, this.selectedShippingDetail$]).pipe(
    map(([selLogId, shippingDetail]) => shippingDetail?.external_webservice_logs.find((log) => log.id == selLogId
    ) || null)
  );

  constructor() { }

  dispatchAllShippings(shippings: ShippingLight[]): void {
    this.shippings = shippings;
    this._shippings.next(shippings);
  }

  dispatchAllShippingDetails(shippingDetails: ShippingDetailLight[]): void {
    if (shippingDetails)
      shippingDetails = [
        ...shippingDetails.map((s) => ({
          ...s,
          product_type_description: this.getProductDescription(
            s.product_type,
            s.product
          ),
        })),
      ];
    this.shippingDetails = shippingDetails;
    this._shippingDetails.next(shippingDetails);
  }

  dispatchDeleteShipping(shippingId: number): void {
    const shippingTmp = [...this.shippings.filter((s) => s.id != shippingId)];

    if (!shippingTmp.length && this.metaShipping) {
      const newMeta = {
        ...this.metaShipping,
        current_page: Math.max(this.metaShipping.current_page - 1, 0),
        total: Math.max(this.metaShipping.total - 1, 0),
        last_page: Math.max(this.metaShipping.last_page - 1, 0),
      };
      this.metaShipping = newMeta;
      this._metaShipping.next(newMeta);
    }

    this.shippings = shippingTmp;
    this._shippings.next(shippingTmp);
  }

  dispatchDeleteShippingDetails(shippingDetailId: number): void {
    const shippingDetailsTmp = [
      ...this.shippingDetails.filter((sd) => sd.id != shippingDetailId),
    ];

    this.shippingDetails = shippingDetailsTmp;
    this._shippingDetails.next(shippingDetailsTmp);
  }

  dispatchShippingDraft(shipping: ShippingComplete | null): void {
    this.shippingDraft = shipping;
    this._shippingDraft.next(shipping);
  }
  dispatchDeleteShippingDraft(): void {
    this.shippingDraft = null;
    this._shippingDraft.next(null);
  }
  dispatchDeleteShippingDetailDraft(id: number): void {
    const tmpShipping: ShippingComplete = {
      ...this.shippingDraft,
    } as ShippingComplete;

    this.shippingDraft = {
      ...tmpShipping,
      details: [
        ...(tmpShipping?.details.filter((s) => s.id && s.id != id) || []),
      ],
    } as ShippingComplete;
    this._shippingDraft.next({ ...this.shippingDraft });
  }

  getDraftTotal(): number {
    const getAccessoryTotal = (accessories?: Accessory[]): number =>
      accessories?.reduce(
        (acc, curr) => acc + (curr.price_data?.sales.final_price ?? 0),
        0
      ) ?? 0;

    return (
      this.shippingDraft?.details.reduce(
        (acc, current) =>
          acc +
          (current.package?.price_data?.sales.final_price ?? 0) +
          (current.document?.price_data?.sales.final_price ?? 0) +
          (current.mail_room?.price_data?.sales.final_price ?? 0) +
          getAccessoryTotal(current.document?.accessories) +
          getAccessoryTotal(current.package?.accessories),
        0
      ) ?? 0
    );
  }

  dispatchShippingsToProcess(shippingsToProcess: ShippingDetailLight[]): void {
    shippingsToProcess = [
      ...shippingsToProcess.map((s) => ({
        ...s,
        product_type_description: this.getProductDescription(
          s.product_type,
          s.product
        ),
      })),
    ];
    this.shippingsToProcess = shippingsToProcess;
    this._shippingsToProcess.next(shippingsToProcess);
  }

  dispatchAddShippingDetail(
    shippingDetail: ShippingDetailLight,
    update: boolean = false
  ): void {
    if (update)
      this.shippingDetails = [
        ...this.shippingDetails.map((s) =>
          s.id === shippingDetail.id
            ? {
              ...shippingDetail,
              product_type_description: this.getProductDescription(
                shippingDetail.product_type,
                s.product
              ),
            }
            : s
        ),
      ];
    else
      this.shippingDetails = [
        ...this.shippingDetails,
        {
          ...shippingDetail,
          product_type_description: this.getProductDescription(
            shippingDetail.product_type,
            shippingDetail.product
          ),
        },
      ];

    this._shippingDetails.next(this.shippingDetails);
    this._selectedShippingDetail.next(null);
  }

  dispatchShipping(shipping: any) {
    if (shipping) {
      this._selectedShipping.next(shipping);
    } else {
      this._selectedShipping.next(null);
    }
  }

  dispatchShippingDetail(shippingDetail: any) {
    if (shippingDetail) {
      this._selectedShippingDetail.next(shippingDetail);
    } else {
      this._selectedShippingDetail.next(null);
    }
  }

  dispatchLog(log: any) {
    if (log) {
      this._selectedLogId.next(log);
    } else {
      this._selectedLogId.next(null);
    }
  }

  dispatchMetaShipping(metaShipping: Meta | null) {
    this.metaShipping = metaShipping;
    this._metaShipping.next(metaShipping);
  }

  dispatchMetaShippingDetail(metaShippingDetail: Meta | null) {
    this._metaShippingDetail.next(metaShippingDetail);
  }

  dispatchMetaShippingsToProcess(meta: Meta | null) {
    this._metaShippingsToProcess.next(meta);
  }

  dispatchChangeTableShippingState(searchTable: any) {
    this._changePageTableShippingState.next(searchTable);
  }
  dispatchChangeTableShippingDetailState(searchTable: any) {
    this._changePageTableShippingDetailState.next(searchTable);
  }
  dispatchChangeShippingToProcessTableState(searchTable: any) {
    this._changePageShippingToProcessTableState.next(searchTable);
  }

  dispatchBarcode(detailId: number | null) {
    this.barcodeShippingId = detailId;
    this._barcodeShippingId.next(detailId);
  }

  dispatchFirstBarcode() {
    const firstId = this.shippingDetailsExternal[0].id;
    this.barcodeShippingId = firstId;
    this._barcodeShippingId.next(firstId);
  }

  dispatchEditBarcode(detailId: number, barcode: string) {
    const details = [...this.shippingDetailsExternal];
    const detail = details.find(d => d.id == detailId);
    if (detail)
      detail.external_tracking = barcode;

    this.shippingDetailsExternal = details;
    this._shippingDetailsExternal.next(details);
  }


  dispatchShippingDetailsExternal(shippingDetailsExternal: ShippingDetailExternal[]): void {
    this.shippingDetailsExternal = shippingDetailsExternal;
    this._shippingDetailsExternal.next(shippingDetailsExternal);
  }

  dispatchNextBarcode() {
    const currIndex =
      this.shippingDetailsExternal.findIndex(
        (sd) => sd.id == this.barcodeShippingId
      ) ?? -1;

    this.dispatchBarcode(
      this.shippingDetailsExternal[currIndex + 1]?.id ??
      this.shippingDetailsExternal[0]?.id
    );

  }

  getProductDescription = (product_type: string, product: string) =>
    productTypeDescriptions.find((p) => p.key == product_type)?.label +
    ' (' +
    (allProductsDescriptions.find((p) => p.key == product)?.label || 'n.a.') +
    ')';
}
